(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset/components/nav-menu/assets/javascripts/oddset-nav-menu.js') >= 0) return;  svs.modules.push('/modules/oddset/components/nav-menu/assets/javascripts/oddset-nav-menu.js');
"use strict";

const {
  useContext
} = React;
const {
  matchPath
} = ReactRouterDOM;
const {
  NavContext
} = svs.oddset.components.nav_context;
const {
  ItemsMenu,
  IconItem
} = svs.ui.ReactItemsMenu;
const moduleBaseUrl = svs.core.data.baseUrl;
const isSelfService = svs.utils.ns.isNamespace('svs.core.config.data.svsconfig.isSelfService') && svs.core.config.data.svsconfig.isSelfService;
const logger = svs.core.log.getLogger('modules:oddset:components:nav-menu');
const isSelected = (route, activePathname) => Boolean(matchPath(activePathname, {
  path: "/".concat(route.path)
}));
const isAnySelected = (routes, activePathname) => routes.some(route => matchPath(activePathname, {
  path: "/".concat(route.path)
}));
const getLinkItemComponent = (isCurrentPlayRoute, isTargetPlayRoute) => {
  const Component = isCurrentPlayRoute && isTargetPlayRoute ? 'div' : 'a';
  return Component;
};
const OddsetNavMenu = _ref => {
  let {
    playRoute,
    activeRoutes,
    renderMobileMenu
  } = _ref;
  const {
    activePathname,
    currentRoute
  } = useContext(NavContext);
  const isCurrentRoutePlayRoute = Boolean(currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.isPlayRoute);
  const visibleRoutes = activeRoutes.filter(tab => renderMobileMenu && tab.activeMobile && !isSelfService || !renderMobileMenu && tab.activeDesktop);
  const handleSoftNavigation = (softNavPage, shouldSoftNav) => {
    if (!shouldSoftNav || !softNavPage) {
      return;
    }
    const navEvent = new CustomEvent('@shapegames/page-navigation', {
      detail: {
        page: softNavPage
      }
    });
    try {
      document.dispatchEvent(navEvent);
    } catch (e) {
      logger.error('Invalid sportsbook navigation call: ', e);
    }
  };
  return React.createElement(ItemsMenu, {
    branding: "oddset-tab",
    centered: true,
    className: "js-oddset-items oddset-nav".concat(renderMobileMenu ? ' oddset-nav--mobile' : ''),
    compact: renderMobileMenu
  }, React.createElement(IconItem, {
    alignment: renderMobileMenu ? 'vertical' : 'horizontal',
    className: "oddset-nav__link",
    Component: getLinkItemComponent(isCurrentRoutePlayRoute, playRoute.isPlayRoute),
    "data-testid": "oddset-route",
    gutter: 1,
    href: "".concat(moduleBaseUrl, "/").concat(playRoute.path),
    iconName: playRoute.icon,
    isSelected: !isAnySelected(visibleRoutes, activePathname),
    key: playRoute.path,
    label: renderMobileMenu && playRoute.shortLabel || playRoute.label,
    onClick: () => handleSoftNavigation(playRoute.softNavPage, isCurrentRoutePlayRoute && playRoute.isPlayRoute),
    to: "/".concat(playRoute.path)
  }), visibleRoutes.map(route => {
    const target = "/".concat(route.path);
    return React.createElement(IconItem, {
      alignment: renderMobileMenu ? 'vertical' : 'horizontal',
      className: "oddset-nav__link",
      Component: getLinkItemComponent(isCurrentRoutePlayRoute, route.isPlayRoute),
      "data-testid": "oddset-route",
      gutter: 1,
      href: "".concat(moduleBaseUrl).concat(target),
      iconName: route.icon,
      isSelected: isSelected(route, activePathname),
      key: route.path,
      label: renderMobileMenu && route.shortLabel || route.label,
      onClick: () => handleSoftNavigation(route.softNavPage, isCurrentRoutePlayRoute && route.isPlayRoute),
      to: target
    });
  }));
};
setGlobal('svs.oddset.components.nav_menu.OddsetNavMenu', OddsetNavMenu);

 })(window);